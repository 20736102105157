import { inject }                     from 'aurelia-framework';
import { ConstructionSchema }         from 'modules/aggregates/lab/tab-schemas/construction-schema';
import { AggregatesLabTest }          from 'modules/aggregates/models/aggregates-lab-test';
import { TestSampleTypesRepository }  from 'modules/management/aggregates/test-sample-types/services/repository';
import { OEDatasRepository }          from 'modules/management/concessions/concessions-tree/lot-constructions/oe-data/services/repository';
import { LotInterventionsRepository } from 'modules/management/concessions/concessions-tree/lot-interventions/services/repository';
import { FieldsRepository }           from 'modules/management/dynamic-fields/association-sectors/services/fields-repository';
import { Sector }                     from 'modules/management/models/sector';

@inject(FieldsRepository, TestSampleTypesRepository, LotInterventionsRepository, OEDatasRepository)
export class MaterialCharacterizationSchema extends ConstructionSchema {

    schemaSlug = 'material_characterization';

    /**
     *
     * @param fieldsRepository
     * @param testSampleTypesRepository
     * @param lotInterventionsRepository
     * @param oeDatasRepository
     */
    constructor(fieldsRepository, testSampleTypesRepository, lotInterventionsRepository, oeDatasRepository) {
        super(lotInterventionsRepository, oeDatasRepository);
        this.fieldsRepository          = fieldsRepository;
        this.testSampleTypesRepository = testSampleTypesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {AggregatesLabTest}
     */
    model(selectedLot) {
        let model = new AggregatesLabTest();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema for "Select Tests" tab
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    async schema(viewModel, readonly = false) {

        // Initialize Construction fields
        await super.schema(viewModel, readonly);

        const LAB_MATERIAL_CHARACTERIZATION = Sector.LAB_MATERIAL_CHARACTERIZATION;

        this.mc_sample_collection_id = {
            type:         'select2',
            key:          'mc_sample_collection_id',
            idPrefix:     this.schemaSlug,
            label:        'form.field.sample-collection',
            remoteSource: () => this.fieldsRepository.getFields(viewModel.sectorId,
                'sample-collection',
                { slug: [LAB_MATERIAL_CHARACTERIZATION] },
            ),
            size:         6,
            attributes:   {
                disabled: readonly,
            },
        };

        this.material_number = {
            type:            'number',
            key:             'material_number',
            label:           'form.field.num',
            size:            6,
            valueConverters: [
                { name: 'numbersOnly' },
            ],
            attributes:      {
                readonly: readonly,
            },
        }

        this.material_designation = {
            type:       'text',
            key:        'material_designation',
            label:      'form.field.designation',
            size:       6,
            attributes: {
                readonly: readonly,
            },
        }

        this.stock_number = {
            type:            'number',
            key:             'stock_number',
            label:           'form.field.stock-num',
            size:            6,
            valueConverters: [
                { name: 'numbersOnly' },
            ],
            attributes:      {
                readonly: readonly,
            },
        }

        this.stock_designation = {
            type:       'text',
            key:        'stock_designation',
            label:      'form.field.stock-designation',
            size:       6,
            attributes: {
                readonly: readonly,
            },
        }

        this.axis_distance = {
            type:       'number',
            key:        'axis_distance',
            label:      'form.field.axis-distance(m)',
            size:       6,
            attributes: {
                readonly: readonly,
            },
        }

        this.depth_quota = {
            type:       'number',
            key:        'depth_quota',
            label:      'form.field.depth-quota(m)',
            size:       6,
            attributes: {
                readonly: readonly,
            },
        }

        this.test_sample_type_id = {
            type:         'select2',
            key:          'test_sample_type_id',
            idPrefix:     this.schemaSlug,
            label:        'form.field.sample-type-to-test',
            remoteSource: () => this.testSampleTypesRepository.all(),
            size:         6,
            attributes:   {
                disabled: readonly,
            },
        };

        this.dimensions = {
            type:     'field-group',
            label:    'form.field.minimum-maximum-dimensions(mm)',
            key:      'dimensions',
            required: false,
            size:     6,
            fields:   [
                {
                    type:            'number',
                    key:             'min_dimension',
                    showLabel:       false,
                    required:        false,
                    size:            3,
                    attributes:      {
                        readonly: readonly,
                    },
                },
                {
                    type:  'divider',
                    label: '/',
                    size:  '5p',
                },
                {
                    type:            'number',
                    key:             'max_dimension',
                    showLabel:       false,
                    required:        false,
                    size:            3,
                    attributes:      {
                        readonly: readonly,
                    },
                },
            ],
        }

        this.usage_text = {
            type:       'text',
            key:        'usage_text',
            label:      'form.field.usage-text',
            size:       6,
            attributes: {
                readonly: readonly,
            },
        };

        this.sample_is_ttmc = {
            type:       'checkbox',
            key:        'sample_is_ttmc',
            label:      'form.field.sample-ref-ttmc',
            size:       6,
            hidden:     viewModel.category === 'treated',
            attributes: {
                readonly: readonly,
            },
        };

        return [
            [this.mc_sample_collection_id],
            [this.material_number, this.material_designation],
            [this.stock_number, this.stock_designation],
            [this.lot_construction_id, this.branch_id, this.edge_id],
            [this.collection_pk],
            [this.number_of_tracks, this.axis],
            [this.axis_distance, this.depth_quota],
            [this.test_sample_type_id, this.dimensions],
            [this.usage_text],
            [this.sample_is_ttmc],
        ];
    }
}
