import { inject }                            from 'aurelia-framework';
import { AggControlTypesRepository }         from 'modules/aggregates/lab/services/control-types-repository';
import { AssociationApplicationsRepository } from 'modules/management/aggregates/association-applications/services/repository';
import { RevisionMaterialTypesRepository }   from 'modules/management/specifications/specifications/specification-revisions/specification-revision-material-types/services/repository';

@inject(RevisionMaterialTypesRepository, AssociationApplicationsRepository, AggControlTypesRepository)
export class GeneralSchema {

    schemaSlug = 'general';

    /**
     *
     * @param materialTypesRepository
     * @param applicationsRepository
     * @param controlTypesRepository
     */
    constructor(materialTypesRepository, applicationsRepository, controlTypesRepository) {
        this.materialTypesRepository = materialTypesRepository;
        this.applicationsRepository  = applicationsRepository;
        this.controlTypesRepository  = controlTypesRepository;
    }

    /**
     * Returns form schema for "Select Tests" tab
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    schema(viewModel, readonly = false) {

        this.number = {
            type:       'text',
            key:        'number',
            label:      'form.field.sample-number',
            size:       6,
            attributes: {
                readonly: true,
            },
        };

        this.date = {
            type:       'material-ui-date-picker',
            key:        'date',
            label:      'form.field.collection-date',
            size:       6,
            attributes: {
                readonly: readonly,
            },
        };

        this.material_type_id = {
            type:         'select2',
            key:          'material_type_id',
            idPrefix:     this.schemaSlug,
            label:        'form.field.material-type',
            remoteSource: () => this.materialTypesRepository.allFromSector(viewModel.sectorId, {
                lot_intervention_id: viewModel.model.lot_intervention_id,
                date:                viewModel.model.created_at ?? 'now',
            }),
            size:         12,
            required:     false,
            attributes:   {
                disabled: readonly,
            },
        };

        this.application_id = {
            type:         'select2',
            key:          'application_id',
            idPrefix:     this.schemaSlug,
            label:        'form.field.application',
            remoteSource: () => this.applicationsRepository.allFromSector(viewModel.sectorId),
            size:         6,
            required:     false,
            attributes:   {
                disabled: readonly,
            },
        };

        this.control_type_id = {
            type:         'select2',
            key:          'control_type_id',
            idPrefix:     this.schemaSlug,
            label:        'form.field.control_type',
            remoteSource: () => this.controlTypesRepository.all(),
            observers:    [
                (newValue) => {
                    viewModel.updateControlTabs(newValue);
                },
            ],
            size:         6,
            required:     false,
            attributes:   {
                disabled: readonly,
            },
        };

        this.external_reference = {
            type:       'text',
            key:        'external_reference',
            label:      'form.field.external_reference',
            size:       12,
            required:   false,
            attributes: {
                readonly: readonly,
            },
        };

        this.observations = {
            type:       'textarea',
            key:        'observations',
            label:      'form.field.observations',
            size:       12,
            required:   false,
            attributes: {
                readonly: readonly,
            },
        };

        return [
            [this.number, this.date],
            [this.material_type_id],
            [this.application_id, this.control_type_id],
            [this.external_reference],
            [this.observations],
        ];
    }

}
